.signin-up-phone-input-wrapper {
  width: 19.125rem;
  max-width: 100%;
  height: 56px;
  position: relative;
  border-radius: 12px;
}

.signin-up-phone-input-wrapper .signin-up-phone-input-container {
  border-radius: 12px;
}

.signin-up-phone-input-wrapper .react-tel-input {
  width: 100%;
  height: 100%;
}

.signin-up-phone-input-wrapper .react-tel-input input {
  width: 100%;
  border-radius: inherit;
  height: 100%;
  border-color: transparent;
  background-color: rgba(242, 242, 242, 0.4);
  font: var(--font-regular-main-design);
  font-size: var(--font-sixteen);
  color: var(--text-color-over-overlay-main-design);
  padding-left: 38px;
}

.signin-up-phone-input-wrapper .react-tel-input .country-list .no-entries-message {
  opacity: 0;
}

.signin-up-phone-input-wrapper.rtl .react-tel-input input {
  padding-left: unset;
  padding-right: 50px;
  direction: ltr;
  text-align: right;
}

.signin-up-phone-input-wrapper .react-tel-input .country-list .search {
  display: flex;
  background-color: var(--secondary-color-main-design);
}

.signin-up-phone-input-wrapper .react-tel-input .country-list {
  background-color: var(--secondary-color-main-design);
  font: var(--font-regular-main-design);
  font-size: var(--font-fifteen);
}

.signin-up-phone-input-wrapper.rtl .react-tel-input .country-list .search-box {
  padding-left: unset;
  padding-right: 0;
  margin-left: unset;
  margin-right: 0;
}

.signin-up-phone-input-wrapper .react-tel-input input[type="search"] {
  color: var(--text-color-over-background-color-main-design);
  background-color: var(--background-color-main-design);
  font: var(--font-regular-main-design);
  font-size: 16px;
}

.signin-up-phone-input-wrapper.rtl .react-tel-input .selected-flag .arrow {
  left: 10px;
}

/* reset the padding left for larger screens */
@media screen and (min-width: 320px) {
  .signin-up-phone-input-wrapper .react-tel-input input {
    padding-left: 48px;
  }
  .signin-up-phone-input-wrapper.rtl .react-tel-input input {
    padding-left: unset;
    padding-right: 48px;
  }
}

.signin-up-phone-input-wrapper .react-tel-input input::placeholder {
  font-size: var(--font-sixteen);
  color: var(--light-text-color-over-background-color-main-design);
}

/* for galaxy fold screens */

.signin-up-phone-input-wrapper .react-tel-input .flag-dropdown {
  border-radius: 12px 0 0 12px;
  border-color: transparent;
  background-color: transparent;
}

.signin-up-phone-input-wrapper .flag-dropdown .selected-flag {
  border-right: none;
  padding: 0 0 0 14px;
}

.signin-up-phone-input-wrapper
  .react-tel-input
  .flag-dropdown
  .selected-flag
  .iti-flag {
  background-color: transparent;
}

.signin-up-phone-input-wrapper:focus-within {
  border: none;
  background-color: transparent;
}

.signin-up-phone-input-wrapper .react-tel-input .country .dial-code {
  color: var(--light-text-color-over-secondary-color-main-design);
}

.signin-up-phone-input-container.error:focus-within,
.signin-up-phone-input-container.error {
  border: 1px solid red;
}

.signin-up-phone-input-error {
  color: red;
  padding: 0;
  font-size: var(--font-eleven);
  margin: 6px 0 0 6px;
  align-self: flex-start;
  position: absolute;
  top: 54px;
  left: 10px;
}

.signin-up-phone-input-wrapper.rtl .signin-up-phone-input-error {
  left: unset;
  right: 10px;
}

.signin-up-phone-input-wrapper .react-tel-input .country-list .country-name {
  margin: 0 6px;
  color: var(--text-color-over-secondary-color-main-design);
}

.signin-up-phone-input-wrapper .flag-dropdown.open .selected-flag,
.signin-up-phone-input-container .selected-flag,
.signin-up-phone-input-container .selected-flag:hover {
  border-radius: 12px 0 0 12px;
  background-color: transparent !important;
}

.signin-up-phone-input-wrapper .react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid var(--text-color-over-overlay-main-design);
}

.signin-up-phone-input-wrapper .react-tel-input .selected-flag .arrow {
  border-top: 4px solid var(--text-color-over-overlay-main-design);
}

.signin-up-phone-input-wrapper.rtl .react-tel-input .selected-flag .flag {
  right: 65%;
}

body[dir="rtl"] .react-tel-input .selected-flag,
body[dir="rtl"] .react-tel-input .flag-dropdown,
body[dir="rtl"] .react-tel-input .flag-dropdown:focus,
body[dir="rtl"] .react-tel-input .flag-dropdown:hover,
body[dir="rtl"] .react-tel-input .selected-flag:hover,
body[dir="rtl"] .react-tel-input .selected-flag:focus,
body[dir="rtl"] .react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 0 10px 10px 0;
}

.signin-up-phone-input-wrapper .react-tel-input .country-list .country.highlight {
  background-color: var(--main-light-color-main-design);
}


